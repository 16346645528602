"use client";

import { useCart, useProducts } from "medusa-react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

import { SearchParamsInterface } from "@/app/(main)/(padding)/wochenangebote/page";
import { SortButton } from "@/components/Button/SortButton";
import { Categories } from "@/components/pages/category";
import { ProductListingTitle, RenderSkeleton } from "@/components/pages/category/Products";
import { ThemeWorldInterface } from "@/components/pages/themeWorld";
import { provideThemeWorldsCategories, ThemeWorldBreadCrumbs, WEEKLY_OFFER_HANDLE } from "@/components/pages/themeWorld/ThemeProducts";
import { Pagination, ViewedProducts } from "@/components/Pagination";
import { ProductCard } from "@/components/Product/ProductCard";
import { medusaClient } from "@/lib/config";
import { usePagination } from "@/lib/hooks/use-pagination";
import { ProductPreviewType } from "@/types/global";

export interface ProductHighlight {
  totals: number;
  ids: string[];
}

export const getWeek = () => {
  const year = moment().year();
  const weekNumber = moment().isoWeek();
  return `${year}-KW${String(weekNumber).padStart(2, "0")}`;
};

export const requestWeeklyOffer = (week: string) => {
  return medusaClient.client.request("GET", `/store/highlights/${week}`);
};

export const Offers = ({ searchParams, themeWorlds }: SearchParamsInterface & { themeWorlds: ThemeWorldInterface[] }) => {
  const { getLimit, getOffset, getPageNumber } = usePagination(21);
  const [sort, setSort] = useState<string>("");
  const { cart } = useCart();
  const [productsIds, setProductsIds] = useState<ProductHighlight>({ totals: 0, ids: [] });

  useEffect(() => {
    const week = getWeek();

    requestWeeklyOffer(week).then(({ ids, totals }) => {
      setProductsIds({ totals: totals, ids: ids });
    });
  }, [searchParams]);

  return (
    <>
      <div className="mb-10 pl-4 lg:block hidden">
        <ThemeWorldBreadCrumbs isWeeklyOffer={true} themeWorld={prepareWeeklyOffer() as unknown as ThemeWorldInterface} />
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-3 mt-10 px-4 lg:block hidden">
          <Categories exactHandle={true} categories={provideThemeWorldsCategories(themeWorlds)} />
        </div>
        <div className="col-span-12 lg:col-span-9 px-4">
          <ProductListingTitle />

          <ProvideProductCardIds
            ids={productsIds.ids}
            regionId={cart?.region_id}
            sort={sort}
            limit={getLimit()}
            offset={getOffset()}
            setSort={setSort}
            pageNumber={getPageNumber()}
          />
        </div>
      </div>
    </>
  );
};

const prepareWeeklyOffer = () => {
  return {
    name: WEEKLY_OFFER_HANDLE.name,
    url_alias: {
      data: {
        attributes: {
          url_path: WEEKLY_OFFER_HANDLE.handle,
        },
      },
    },
  };
};

interface ProvideProductCardIdsInterface {
  ids: string[];
  regionId: string | undefined;
  sort: string;
  limit: number;
  offset: number;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  pageNumber: number;
}

export const ProvideProductCardIds = ({ ids, regionId, sort, limit, offset, setSort, pageNumber }: ProvideProductCardIdsInterface) => {
  const { products, isLoading, count } = useProducts(
    {
      id: ids,
      region_id: regionId,
      limit: limit,
      offset: offset,
      order: sort.length ? sort : "title",
    },
    { enabled: !!regionId },
  );

  if (isLoading) {
    return (
      <Layout setSort={setSort} limit={limit} count={!ids.length ? 0 : count} pageNumber={pageNumber}>
        <RenderSkeleton />
      </Layout>
    );
  }

  if (!products?.length || !ids.length) {
    return (
      <Layout setSort={setSort} limit={limit} count={!ids.length ? 0 : count} pageNumber={pageNumber}>
        <div className="col-span-12">
          <p className="ml-5">Keine Produkte gefunden</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout setSort={setSort} limit={limit} count={!ids.length ? 0 : count} pageNumber={pageNumber}>
      {products?.map((product, index) => {
        return (
          <div className="col-span-6 lg:col-span-4 border rounded-3xl" key={index}>
            <ProductCard className="" product={product as ProductPreviewType} />
          </div>
        );
      })}
    </Layout>
  );
};

interface LayoutInterface {
  setSort: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
  limit: number;
  pageNumber: number;
  count?: number;
}

const Layout = ({ setSort, children, limit, pageNumber, count }: LayoutInterface) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 mt-3 border-y border-gray-300">
          <SortButton setSort={setSort} />
        </div>

        {children}
      </div>

      <div className="flex flex-wrap justify-between mt-14">
        <div>
          <ViewedProducts limit={limit} pageNumber={pageNumber} totals={count ?? 0} />
        </div>
        <Pagination limit={limit} totals={count} />
      </div>
    </>
  );
};
